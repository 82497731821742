// 服务链管理
<template>
  <div class="manage page_width">
    <div class="manage_header">
      <page-header
        icon="iconfuwulianguanli"
        label="服务链管理" />
    </div>
    <div class="manage_main">
      <div class="tw-flex tw-items-center">
        <el-button
          type="primary"
          size="small"
          @click="onDetail('create')">
          服务链定制
        </el-button>
        <el-input
          v-model="searchCondition.keywords"
          size="small"
          placeholder="请输入服务链名称搜索"
          suffix-icon="el-icon-search"
          style="margin: 0 10px; width: 300px"
          @keyup.native.enter="onSearch">
        </el-input>
        <el-select
          v-model="searchCondition.industryId"
          size="small"
          placeholder="请选择行业领域"
          :clearable="true"
          @change="onSearch">
          <el-option
            v-for="item in industrySectorList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="manage_main_list">
        <div
          class="item"
          v-for="item in tableData"
          :key="item.id"
          @click="onDetail('edit', item.id)">
          <p class="item_name text_size_20 tw-font-bold">
            {{item.name}}
          </p>
          <p class="item_desc text_size_14">
            {{item.description}}
          </p>
          <div class="item_chain chain">
            <div
              class="tw-flex tw-items-center"
              :class="index === item.steps.length - 1 ? 'last' : ''"
              style="margin-top: 20px"
              v-for="(step, index) in item.steps"
              :key="step.id">
              <span
                v-if="index !== 0"
                class="chain_dash" />
              <div class="chain_item tw-flex tw-items-center">
                <span class="chain_item_num">
                  {{ index + 1 }}
                </span>
                <div class="chain_item_name text_size_14">
                  {{step.serviceTypeName}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="manage_main_pagination tw-flex tw-justify-center tw-items-center">
        <el-pagination
          hide-on-single-page
          background
          @current-change="pageNumChange"
          :current-page.sync="pagination.pageNum"
          :page-size="pagination.pageSize"
          layout="prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    return {
      searchCondition: {
        keywords: '',
        industryId: ''
      },
      tableData: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      } // 分页对象
    }
  },
  computed: {
    // 行业领域下拉列表
    industrySectorList () {
      return this.$store.state.industrySectorList
    }
  },
  mounted () {
    this.getTableData(1)
  },
  methods: {
    onSearch () {
      this.getTableData(1)
    },
    getTableData (pageNum) {
      api.getServiceChainList({
        keywords: this.searchCondition.keywords,
        industryId: this.searchCondition.industryId,
        pageNum,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    pageNumChange (page) {
      this.getTableData(page)
    },
    onDetail (type, id) {
      let href = ''
      switch (type) {
        case 'create':
          href = `/service-chain/${type}`
          break
        case 'edit':
          href = `/service-chain/${type}/${id}`
          break
      }
      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.manage {
  margin-bottom: 25px;
  &_header {
    padding: 30px 0 20px 0;
  }
  &_main {
    padding: 30px 40px;
    background: #ffffff;
    border: 1px solid #dcdfe6;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    &_list {
      .item {
        width: 1120px;
        min-height: 250px;
        margin-top: 20px;
        padding: 10px 30px 30px 30px;
        background: #ffffff;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        &:hover {
          cursor: pointer;
          border: 1px solid #3473e6;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        }
        &_name {
          color: #303133;
          line-height: 28px;
          margin: 20px 0;
        }
        &_desc {
          width: 900px;
          color: #606266;
          line-height: 20px;
        }
        &_chain {
          margin-top: 5px;
        }
      }
    }
    &_pagination {
      margin-top: 20px;
      height: 40px;
    }
  }
}

.chain {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &_item {
    &_num {
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      color: #606266;
      background: #ffffff;
      border: 1px solid #dcdfe6;
      border-radius: 50%;
      transform: scale(0.83);
    }
    &_name {
      margin-left: 6px;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
      color: #606266;
      background: #f7f8f9;
      border: 1px solid #dcdfe6;
      border-radius: 2px;
    }
  }
  &_dash {
    display: inline-block;
    width: 40px;
    height: 1px;
    margin: 0 10px;
    border-top: 1px dashed #707070;
  }
  .last {
    .chain_item_num {
      color: #3473e6;
      background: rgba(52, 115, 230, 0.1);
      border: 1px solid rgba(52, 115, 230, 0.3);
    }
    .chain_item_name {
      color: #3473e6;
      background: rgba(52, 115, 230, 0.1);
      border: 1px solid rgba(52, 115, 230, 0.3);
    }
  }
}
</style>
